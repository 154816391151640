.main-header {
    color: rgba(255, 255, 255, 0.65);
}

.main-title a {
    color: rgba(255, 255, 255, 0.65);
    font-size: 18px;
}

.main-user {
    color: #1890ff;
    cursor: pointer;
}

.main-table .main-table-actions .ant-btn {
    margin: 0 10px;
}

.main-form-buttons {
    text-align: center;
}

.main-form-buttons .ant-btn {
    margin: 0 20px;
}
